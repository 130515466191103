<template>
  <Breadcrumb />
  <div class="d-flex justify-content-end">
    <router-link to="New" class="btn btn-success btn-action-new-page"
      ><i class="bi bi-plus"></i>
      {{
        $t("ApiToken.NewButton", {}, { locale: this.$store.state.activeLang })
      }}</router-link
    >
  </div>
  <Grid :settings="gridSettings" :columns="gridColumns" />
</template>
<script>
export default {
  name: "ApiTokenList",
  components: {},
  data() {
    return {
      gridSettings: {
        action: "ApiTokenList",
        requestUrl: String.format("/Lcdp-CompanyApiTokenList"),
        requestUrlRouteParamNames: [],
        routeRequiredParameters: [
          {
            key: "apiTokenId",
            value: "publicId",
            isRouteParameter: false,
          },
        ],

        serialNoLink: `#/ApiToken/Edit/`,
        isGatewayRequest: true,

        allowSearchPanel: true,
        allowExcelExport: false,
        allowDragAndDrop: false,
        allowPaging: true,
        allowDeleting: true,
        deleteActionUrl: "/Lcdp-CompanyApiTokenDelete",
        autoOrderProcess: true,

        buttons: [
          {
            name: "edit",
            cssClass: "btn-warning",
            iconClass: "bi-pencil-square",
            routeButton: true,
            routeObj: {
              name: "ApiTokenEdit",
              params: {
                apiTokenId: "",
              },
            },
          },
        ],
      },
      gridColumns: [
        {
          text: this.$t(
            "ApiToken.Name",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "name",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "ApiToken.Description",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "description",
          type: "string",
          visible: true,
          width: 0,
          minWidth: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "ApiToken.UserName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "userName",
          type: "string",
          visible: true,
          width: 0,
          minWidth: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "ApiToken.Token",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "token",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "ApiToken.UsageCount",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "usageCount",
          type: "string",
          visible: true,
          width: 0,
          minWidth: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isActive",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
      ],
    };
  },
  methods: {},
};
</script>
